import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-medium-rounded-button',
  templateUrl: './medium-rounded-button.component.html',
  styleUrls: ['./medium-rounded-button.component.scss'],
})
export class MediumRoundedButtonComponent {
  @Input() isPrimary: boolean;
  @Input() title: string;
  @Input() type: string;
  @Input() parentForm: FormGroup;
  @Input() public disabled = false;

  constructor() {}
}
