<div
  #contentContainer
  (@dialogAppearanceAndHidingAnimation.done)="onAnimationStart($event)"
  (@dialogAppearanceAndHidingAnimation.start)="onAnimationStart($event)"
  *ngIf="isVisible"
  [@dialogAppearanceAndHidingAnimation]
  class="content-container">
  <div class="title-container"><label class="title">{{config.title}}</label></div>
  <div class="message-container"><label class="message">{{config.message}}</label></div>

  <div class="btn-container">
    <button (click)="btnCancel_OnClick()" *ngIf="!!config.cancelBtnTitle" class="btn">{{config.cancelBtnTitle}}</button>
    <button (click)="btnConfirm_OnClick()" class="btn">{{config.confirmBtnTitle}}</button>
  </div>
</div>
