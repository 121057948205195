import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormInputComponent } from './components/form-input/form-input.component';
import { ValidatorMessageComponent } from '@shared/components/validator-message/validator-message.component';
import { MediumRoundedButtonComponent } from '@shared/components/medium-rounded-button/medium-rounded-button.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { BigRoundedButtonComponent } from '@shared/components/big-rounded-button/big-rounded-button.component';
import { LoaderConfig } from '@interops/loader/loader.config';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";

export function playerFactory(): LottiePlayer {
  return player;
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    FormInputComponent,
    ValidatorMessageComponent,
    MediumRoundedButtonComponent,
    LoaderComponent,
    BigRoundedButtonComponent,
    DialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OverlayModule,
    TranslateModule,
    LottieModule.forRoot({
      player: playerFactory,
    }),
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    FormInputComponent,
    MediumRoundedButtonComponent,
    LoaderComponent,
    BigRoundedButtonComponent,
    NgxMaskModule,
    TranslateModule,
    NgxCurrencyModule
  ],
})
export class SharedModule {}
