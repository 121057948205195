import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent {
  @Input() controlName: string;
  @Input() title: string;
  @Input() control: UntypedFormControl;

  @HostBinding('class.form-group') formGroup = true;

  constructor(public translateServices: TranslateService) {}
}
