import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { LOADER_CONFIG } from '@interops/loader/loader.token';
import { LoaderConfig } from '@interops/loader/loader.config';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  public options: AnimationOptions;
  public heightPx = '150px';
  public widthPX = '150px';

  constructor(@Inject(LOADER_CONFIG) readonly config: LoaderConfig) {
    this.height = config.height;
    this.width = config.width;
  }

  private _height: number;

  @Input() set height(value: number) {
    this._height = value;
    this.heightPx = `${this._height}px`;
  }

  private _width: number;

  @Input() set width(value: number) {
    this._width = value;
    this.widthPX = `${this._width}px`;
  }

  public ngOnInit(): void {
    this.options = {
      path: '/assets/animation/loading_animation.json',
      autoplay: true,
      loop: true,
    };
  }

  public ngOnDestroy(): void {}
}
