export class DialogConfig {
  public title: string;
  public message: string;
  public type: DialogConfigType = DialogConfigType.warning;
  public confirmBtnTitle = 'Ok';
  public cancelBtnTitle?: string = null;

  constructor(title: string, message: string) {
    this.title = title;
    this.message = message;
  }
}

export enum DialogConfigType {
  error,
  warning,
  success,
  info,
}
