import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { HttpClientModule } from '@angular/common/http';

// const interceptorProviders =
//   [
//     {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
//     {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true}
//   ];

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedModule],
})
export class CoreModule {}
