import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-big-rounded-button',
  templateUrl: './big-rounded-button.component.html',
  styleUrls: ['./big-rounded-button.component.scss'],
})
export class BigRoundedButtonComponent {
  @Input() imageName: string;
  @Input() imageHeight: number;
  @Input() imageWidth: number;
  @Input() title: string;
  @Input() isPrimary: boolean;
  @Input() public disabled = false;

  constructor() {}
}
