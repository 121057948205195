import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

interface Config {
  key: string;
  message: string;
}

@Component({
  selector: 'app-validator-message',
  templateUrl: './validator-message.component.html',
  styleUrls: ['./validator-message.component.scss'],
})
export class ValidatorMessageComponent {
  @Input() field: UntypedFormControl;
  @Input() fieldName = 'Field';

  constructor(private _translateService: TranslateService) {}

  public get validatorMessages(): string[] {
    const field = this.field;
    if (!field || !field.errors) {
      return [];
    }
    const errors: string[] = [];

    const configs: Config[] = [
      { key: 'required', message: `${this.fieldName} is required` },
      { key: 'requiredTrue', message: `${this.fieldName} should be positive` },
      { key: 'email', message: `${this.fieldName} should contain e-mail` },
      { key: 'pattern', message: `${this.fieldName} does not match to pattern` },
    ];

    if (field.errors.hasOwnProperty('customError')) {
      const errorMsg =
        typeof field.errors.customError === 'string' && field.errors.customError.length > 0 ? field.errors.customError : 'Error!';

      configs.push({ key: 'customError', message: errorMsg });
    }

    if (field.errors.hasOwnProperty('minlength')) {
      configs.push({ key: 'minlength', message: `Minimum length ${field.errors.minlength.requiredLength}` });
    }

    if (field.errors.hasOwnProperty('maxlength')) {
      configs.push({ key: 'maxlength', message: `Maximum length ${field.errors.minlength.requiredLength}` });
    }

    if (field.errors.hasOwnProperty('min')) {
      configs.push({ key: 'min', message: `Minimum value ${field.errors.min.min}, actual value ${field.errors.min.actual}` });
    }

    if (field.errors.hasOwnProperty('max')) {
      configs.push({ key: 'max', message: `Maximum value ${field.errors.max.max}, actual value ${field.errors.max.actual}` });
    }

    if (field.errors.hasOwnProperty('isNotImage')) {
      configs.push({ key: 'isNotImage', message: `The selected image type is not supported. Only png, jpeg are supported.` });
    }

    if (field.errors.hasOwnProperty('isNotUrl')) {
      configs.push({ key: 'isNotUrl', message: this._translateService.instant('Errors.IsNotUrl') });
    }

    if (field.errors.hasOwnProperty('isNotPhone')) {
      configs.push({ key: 'isNotPhone', message: this._translateService.instant('Errors.IsNotPhone') });
    }

    if (field.errors.hasOwnProperty('dateMinimum')) {
      configs.push({
        key: 'dateMinimum',
        message: `Minimum date ${field.errors.dateMinimum.dateMinimum}, actual date ${field.errors.dateMinimum.actual}`,
      });
    }

    Object.keys(field.errors).forEach((error: string) => {
      errors.push(configs.find((value) => value.key === error)?.message);
    });

    return errors;
  }
}
